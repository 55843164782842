#home {
    padding-top: 12%;
}

.fontweight11 {
    font-weight: 600;
    font-size: 18px;
    text-align: left;
}

.marginForContactUs {
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: 3%;
    margin-right: 10%;
}

#DemoHeading {
    line-height: inherit;
}

#backgroundForCard {
    margin-top: 5%;
    height: fit-content;
}

#marginTopForContactUs {
    margin-top: 1%;
}

#DemoHeading1 {
    line-height: 1.5rem;
    font-size: 2rem;
    margin-bottom: 4px;
}

.content {
    margin: 3%;
}

.line {
    position: absolute;
    background: rgba(99, 114, 255, 0.8);
}

#backgroundForCard {
    background-color: #2f3032;
    margin-bottom: 5%;
    margin-top: 10%;
}

.formContent {
    margin: 3%;
}

.backgoundImg {
    background: linear-gradient(to bottom, #eef0f3, #ffffff);
}

.form {
    background: linear-gradient(to right, #e3e5f0 0%, #1f7fe6 100%);
}

.form h1 {
    text-align: center;
    color: white;
}

.submitFormButton {
    margin-left: 40%;
    margin-top: 3%;
    color: white;
    background-color: black;
}

.errorMessege {
    color: red;
    font-size: 1rem;
}

.margintopby4 {
    margin-top: 20%;
}

.imgwidth {
    height: auto;
}

.demoCss {
    width: 100%;
}

.fontAndColor {
    color: #fff;
    font-size: 1.5rem;
}

.dropdown-toggle:after {
    margin-left: .5rem;
}

@media (min-width: 2500px) {
    .screenshot {
        background-size: unset;
    }
}

.innersubpoints {
    margin-bottom: 3%
}


@media (min-width:200px) and (max-width:900px) {
    section {
        padding-top: 80px
    }

    .feature-style li {
        margin-bottom: 2%
    }

    .MuiGrid-spacing-xs-4>.MuiGrid-item {
        padding: 3%;
        margin-left: 15px;
        margin-right: 15px;
        text-align: justify
    }

    .tenPixelFontSize {
        font-size: 10px
    }

    .fourteenPixelFontSizw {
        font-size: 14px
    }
}

@media (min-width:200px) and (max-width:400px) {
    #marginTopForContactUs {
        margin-top: 10%
    }
}

#backgroundForCard {
    margin-top: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}





.content {
    margin: 3%;
    margin-top: 20%;
}

.line {
    position: absolute;
    background: rgba(99, 114, 255, .8);
}

#backgroundForCard {
    background-color: #2f3032;
    margin-bottom: 5%;
    margin-top: 10%;
}

.formContent {
    margin: 3%;
}

.backgoundImg {
    background: linear-gradient(180deg, #eef0f3, #fff);
}

.form {
    background: linear-gradient(90deg, #e3e5f0 0, #1f7fe6);
}

.form h1 {
    text-align: center;
    color: #fff;
}


.formContent1 {
    margin-left: 3%;
    margin-right: 3%;
}

.formContent1 h5 {
    color: #000;
    font-weight: 500;
}

.errorMessege {
    color: red;
    font-size: 1rem;
}

.marginBottomForSignUp {
    margin-top: 2%;
    margin-bottom: 5%;
}

.classofInvalid {
    color: red;
    margin-left: 3%;
    font-size: 10px
}

.btn-custom.theme-color {
    background-image: linear-gradient(90deg, #19d3dc, #18e7d3, #18e7d3, #19d2dd);
}

.btn-custom:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out;
}

.btn-custom:focus {
    outline: none;
}

.btn-customdemo {
    background-color: #fff;
    font-size: 14px;
    border-radius: 50px;
    text-transform: none;
    padding: 10px 25px;
    cursor: pointer;
    border: none;
    background-size: 300% 100%;
    transition: all .4s ease-in-out;
}

.btn-customimp.theme-color {
    background-image: linear-gradient(90deg, #fff, #fff, #fff, #fff)
}

.btn-customimp:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out
}

.btn-customimp:focus {
    outline: none
}

.colorfont {
    color: #fff;
}

.margintopandbottom {
    margin-top: 7%;
    margin-bottom: 10%;
}

.btn-back:hover i {
    -webkit-animation: btn-animation 1s linear infinite;
    animation: btn-animation 1s linear infinite;
    -webkit-transform: scale(2);
    transform: scale(2);
}

.tap-top {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 150px;
    right: 30px;
    z-index: 99;
    color: #fff;
    text-align: center;
    background: linear-gradient(0deg, #18e7d3, #2bd4df);
    border-radius: 100%;
    font-size: 22px;
    cursor: pointer;
    line-height: 2.2;
    display: none;
    border: 1px solid #fff;
}