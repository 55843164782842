/*=====================
    Responsive CSS start
==========================*/

@media (min-width: 2500px) {
	.screenshot {
		background-size: unset;

	}
}

@media (min-width: 1670px) and (max-width: 1800px) {
	.home-decor .home-circle2 {
		top: 150px;
		right: 25px;
	}

	.home-decor .home-circle3 {
		top: 235px;
		right: 55px;
	}

	.home-right {
		width: 100%;
	}

	.team .team-circle3 {
		top: 657px;
		right: 1540px;
	}
}

@media (min-width: 1367px) and (max-width: 1669px) {
	.home-decor .home-circle2 {
		top: 150px;
		right: 25px;
	}

	.home-decor .home-circle3 {
		top: 235px;
		right: 55px;
	}

	.home-right {
		width: 100%;
	}

	.home-two {
		background-size: unset;
	}

	.feature-phone {
		top: 110px;
		left: 185px;
	}

	.feature-phone img {
		width: 70%;
	}

	.features {
		text-align: center;
	}

	.features-img {
		width: 284px;
	}

	.pc-bg {
		width: 400px;
		height: 240px;
		background-size: contain;
	}

	.img-scroll {
		left: 20px;
		width: calc(100% - 40px);
		height: calc(100% - 57px);
		top: 11px;
	}

	.border-shape-two {
		width: 95%;
	}

	.darkHeader.theme-nav .nav-item {
		padding: 15px 0 15px 7px;
	}

	.team .team-circle3 {
		top: 678px;
		right: 1201px;
	}

	.contact-right img {
		width: 70%;
		top: 0;
	}

	.screenshot-decor .screenshot-circle3 {
		bottom: 0;
		left: 50px;
		top: auto;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}

@media (min-width: 2000px) {
	.home-right {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}

@media (min-width: 1500px) and (max-width: 1366px) {
	.home-right {
		margin-top: -30px;
	}
}

@media (min-width: 991px) {
	.navbar-expand-lg .navbar-collapse {
		height: auto !important;
	}
}

@media (max-width: 1366px) {
	.home-right {
		width: 90%;
	}

	.back-img .home-contain {
		margin-top: -60px;
	}

	.home-contain {
		margin-top: 50x;
	}

	.home-two {
		background-size: auto;
		padding-bottom: 0;
		height: auto !important;
	}

	.contact-right {
		width: 70%;
	}

	.screenshot-contain {
		height: 500px;
	}

	.about {
		background-position: right;
	}

	.feature {
		background-position: bottom;
		min-height: 730px;
	}

	.feature-phone img {
		height: 600px;
	}

	.about-right {
		height: 500px;
		width: 500px;
	}

	.home-right {
		width: 65%;
		float: right;
		position: absolute;
		right: 0;
		top: -50px;
	}

	.home-decor .home-circle1 {
		top: 120px;
		left: 30px;
	}

	.home-decor .home-circle2 {
		top: 130px;
		right: -30px;
	}

	.home-decor .home-circle3 {
		top: 200px;
		right: 25px;
	}

	.feature-phone {
		top: 126px;
		left: 50px;
	}

	.feature-phone img {
		width: 65%;
	}

	.team-block {
		padding: 20px;
	}

	.team-block:hover .team-social li:nth-child(1) {
		-webkit-animation: none;
		animation: none;
	}

	.team-block:hover .team-social li:nth-child(2) {
		-webkit-animation: none;
		animation: none;
	}

	.team-block:hover .team-social li:nth-child(3) {
		-webkit-animation: none;
		animation: none;
	}

	.team-block:hover .team-social li:nth-child(4) {
		-webkit-animation: none;
		animation: none;
	}

	.team-block:hover .team-social li:nth-child(5) {
		-webkit-animation: none;
		animation: none;
	}

	.team .team-circle1 {
		top: auto;
		left: 60px;
	}

	.team .team-circle2 {
		top: auto;
		left: 10px;
	}

	.team .team-circle3 {
		right: 0;
		left: unset;
		bottom: 90px;
	}

	.screenshot {
		background-size: unset;
		margin-top: 500px;
	}

	.screenshot-decor .screenshot-circle2 {
		top: 700px;
		right: 20%;
	}

	.screenshot-decor .screenshot-circle1 {
		top: 700px;
		right: 30%;
	}

	.screenshot-decor .screenshot-circle3 {
		left: 29%;
	}

	.price-type {
		padding: 10px;
	}

	.price-feature {
		margin: 20px auto;
	}

	.blog-text h3 {
		margin-bottom: 10px;
	}

	.blog-text h5 {
		font-size: 12px;
	}

	.home-two {
		background-size: auto;
	}

	.home-three {
		background-size: cover;
	}

	.landing-caption {
		line-height: 1.5 !important;
	}

	.pc-bg {
		width: 360px;
		height: 210px;
		background-size: contain;
	}

	.img-scroll {
		width: calc(100% - 34px);
		height: calc(100% - 40px);
		left: 17px;
		top: 10px;
	}

	.border-shape-two {
		width: 95%;
	}

	.features-img {
		width: 284px;
	}

	.coming-title {
		margin-top: 70px;
	}

	.features {
		text-align: center;
	}

	.features img {
		width: 100px;
	}

	.features-title {
		font-size: 14px;
	}

	.tapl-title {
		font-size: 32px;
	}

	.home-style .modal.video-play .modal-dialog {
		-webkit-transform: translate(-20%, 0);
		transform: translate(-20%, 0);
	}

	.darkHeader.theme-nav .nav-item {
		padding: 20px 0;
	}

	.home-contain p {
		line-height: 22px;
	}

	.feature-decor .feature-circle1 {
		top: auto;
	}

	.about-decor .about-circle1 {
		right: 0;
	}

	.price-block .btn-custom {
		padding: 11px 25px;
	}

	.testimonial-carousel.owl-carousel button.owl-prev {
		left: -80px;
	}

	.testimonial-carousel.owl-carousel button.owl-next {
		right: -80px;
	}

	.footer-form .form-control {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.footer-text h2 {
		margin: 15px auto;
	}

	iframe.video-play {
		width: 700px;
		height: 600px;
	}

	.animated-circle.three img {
		bottom: 50px;
	}

	.animated-circle.three:before {
		bottom: 68px;
	}

	.animated-circle.three:after {
		bottom: 68px;
	}

	img.main-logo {
		width: 100%;
	}

	.landing-circle3 img {
		top: 0;
		left: 14px;
	}

	.landing-circle2 img {
		top: 160px;
		left: 125px;
	}

	.landing-circle1 img {
		top: 10px;
		left: 200px;
	}
}

@media (max-width: 1200px) {
	img.main-logo {
		width: unset;
	}

	.home-contain {
		margin-top: 0;
	}
}

@media (max-width: 1199px) {
	.coming-soon .innerpage-decor {
		opacity: 0.1;
	}

	.mob-mocup {
		width: 248px;
		left: 355px;
	}

	.home-three .contain {
		left: calc(50% + 10px);
	}

	.footer-style li+li {
		margin-top: 30px;
	}

	h1 {
		font-size: 46px;
	}

	h2 {
		font-size: 26px;
	}

	h4 {
		font-size: 14px;
	}

	section {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.testimonial-text {
		margin-bottom: 80px;
	}

	.about-text h3 {
		padding-top: 0;
		padding-bottom: 0;
	}

	.footer {
		padding-top: 80px;
	}

	.team .team-circle3 {
		display: none;
	}

	.home-right {
		-webkit-transform: translateY(70px) !important;
		transform: translateY(70px) !important;
	}

	.home-decor .home-circle1 {
		top: 125px;
		left: 65px;
	}

	.home-decor .home-circle2 {
		right: -30px;
	}

	.home-decor .home-circle3 {
		top: 211px;
		right: 5px;
	}

	.feature-phone {
		top: 126px;
		left: 75px;
	}

	.top-margin {
		margin-top: 20px;
	}

	.mobile-light-left {
		left: 35%;
	}

	.mobile-light-right {
		right: 35%;
	}

	@-webkit-keyframes highlight-left {
		0% {
			opacity: 0;
			top: 320px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -120px;
		}
	}

	@keyframes highlight-left {
		0% {
			opacity: 0;
			top: 320px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -120px;
		}
	}

	@-webkit-keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 320px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	@keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 320px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	.screenshot-decor .screenshot-circle1 {
		top: 700px;
		right: 25%;
	}

	.screenshot-decor .screenshot-circle2 {
		top: 700px;
		right: 13%;
	}

	.screenshot-decor .screenshot-circle3 {
		left: 19%;
		bottom: 0;
	}

	.team .team-circle1 {
		top: auto;
		right: -70px;
		left: auto;
	}

	.team .team-circle2 {
		left: 0;
	}

	.team-box {
		padding: 15px;
	}

	.blog-text h3 {
		margin-bottom: 10px;
	}

	.blog-text h6 {
		margin-top: 15px;
	}

	.blog-text h5 {
		margin: 0;
		line-height: 20px;
	}

	.price-item {
		padding-top: 0;
		padding-bottom: 0;
	}

	.address-bar {
		padding-top: 70px;
		padding-bottom: 70px;
	}

	.contact-decor .contact-circle2 {
		top: auto;
	}

	.contact-decor .contact-circle1 {
		position: absolute;
		top: auto;
		left: 280px;
	}

	.testimonial-decor .testi-circle1 {
		left: 0;
		top: 10%;
	}

	.testimonial-decor .testi-circle2 {
		left: auto;
		top: auto;
		bottom: 0;
	}

	.index-footer {
		margin: 0 auto;
		position: absolute;
		width: 100%;
	}

	.footer-text {
		padding-top: 0;
	}

	.sidebar-space {
		margin-bottom: 30px;
	}

	.marg-20 {
		margin: 10px auto;
	}

	.blog-block {
		padding: 5px;
	}

	.home-two {
		background-size: cover;
		background-position: 70%;
	}

	.coming-title {
		font-size: 28px;
	}

	.margin-top-bottom {
		margin-top: 70px;
	}

	.tapl-title {
		font-size: 28px;
	}

	.features-img {
		width: 230px;
	}

	.theme-nav .nav-item {
		font-size: 13px;
		padding: 30px 0;
	}

	.home-contain h4 {
		font-size: 14px;
	}

	.about-text p {
		line-height: 1.5;
	}

	.about-style li {
		margin-bottom: 15px;
		min-height: 50px;
	}

	.team-social li {
		margin: 0 2px;
	}

	.team-block .team-social {
		opacity: 1;
	}

	.team-block .team-social li a {
		padding: 5px;
	}

	.blog-pagin a.page-link {
		font-size: 14px;
	}

	.contact-right img {
		width: 85%;
	}

	.testimonial-text h6 {
		margin-bottom: 0;
	}

	.blog-detail .blog-text h5 {
		font-size: 14px;
	}

	.breadcrumb-bg h2 {
		font-size: 20px;
	}

	.blog-list>div+div+div .blog-item {
		margin-top: 40px;
	}

	.blog-cat-detail ul li i {
		margin-right: 5px;
	}

	.pages-space>div+div+div {
		margin-top: 50px;
	}

	@-webkit-keyframes movebounce {
		0% {
			-webkit-transform: translateX(0px);
			transform: translateX(0px);
		}

		50% {
			-webkit-transform: translateX(20px);
			transform: translateX(20px);
		}

		100% {
			-webkit-transform: translateX(0px);
			transform: translateX(0px);
		}
	}

	@keyframes movebounce {
		0% {
			-webkit-transform: translateX(0px);
			transform: translateX(0px);
		}

		50% {
			-webkit-transform: translateX(20px);
			transform: translateX(20px);
		}

		100% {
			-webkit-transform: translateX(0px);
			transform: translateX(0px);
		}
	}
}

@media (max-width: 1024px) {
	.feature-phone {
		top: 100px;
	}

	.about {
		background-size: contain;
	}

	.about-decor .about-circle1 {
		top: 0;
	}

	.about-decor .about-circle2 {
		bottom: 0;
		right: -150px;
	}

	.feature-phone {
		left: 0;
		top: unset;
	}

	.testimonial-text {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	.testimonial-text p {
		font-size: 14px;
		letter-spacing: 1px;
		line-height: 1.5;
	}

	.mobile-light-left {
		left: 35%;
	}

	.mobile-light-right {
		right: 35%;
	}

	.contact {
		background-size: cover;
	}

	@-webkit-keyframes highlight-left {
		0% {
			opacity: 0;
			top: 330px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -120px;
		}
	}

	@keyframes highlight-left {
		0% {
			opacity: 0;
			top: 330px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -120px;
		}
	}

	@-webkit-keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 100px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 330px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	@keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 330px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	.index-footer {
		position: relative;
	}

	.home-two {
		background-position: 62%;
	}

	.pc-bg {
		width: 300px;
		height: 180px;
	}

	.img-scroll {
		left: 15px;
		top: 9px;
		width: calc(100% - 29px);
		height: calc(100% - 38px);
	}

	.template-bg {
		height: auto;
	}

	.home-decor .home-circle1 {
		top: auto;
		left: 65px;
		bottom: 0;
	}

	.contact-decor .contact-circle2 {
		top: auto;
		bottom: 0;
		left: 10%;
	}

	.features li {
		padding: 40px 0;
	}
}

@media (max-width: 992px) {
	.feature .feature-style li:last-child {
		margin-bottom: 30px;
	}

	.feature .sm-m-top .feature-style li:last-child {
		margin-bottom: 0px;
	}

	.testimonial {
		background-size: cover;
	}

	.footer-text {
		margin-top: 0;
	}

	.feature-phone {
		top: 100px;
	}

	.feature-decor .feature-circle1 {
		display: none;
	}

	.team .team-circle3 {
		display: none;
	}

	.contact-right img {
		width: 67%;
	}

	section * {
		-webkit-animation: none !important;
		animation: none !important;
	}

	.mobile-light-right,
	.mobile-light-left {
		display: none;
	}

	.d-align-center {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}

@media (max-width: 991px) {
	.navbar-toggler {
		padding: 0.25rem 0.55rem;
		font-size: 15px;
		-ms-flex-item-align: auto;
		align-self: auto;
	}

	.theme-nav.darkHeader {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.theme-nav.darkHeader .navbar-brand img {
		width: 120px;
	}

	.home-style-two .navbar-light .default-nav .navbar-nav .nav-link.active,
	.three .navbar-light .default-nav .navbar-nav .nav-link.active {
		color: #18e7d3;
	}

	.not-found .innerpage-decor {
		display: none;
	}

	.not-found .error-font {
		font-size: 180px;
	}

	.mob-mocup {
		width: 245px;
		left: 237px;
	}

	.home-three .contain {
		left: calc(50% + 0px);
	}

	.home-two .contain {
		top: calc(50% + 5px);
	}

	.home-style nav.navbar .navbar-nav .active>.nav-link {
		color: #17f3d4;
	}

	.about-right {
		height: 350px;
		width: 350px;
	}

	.feature {
		min-height: auto;
	}

	.team {
		background-position: bottom;
	}

	#navbarSupportedContent {
		background: white;
	}

	#navbarSupportedContent .nav-item {
		padding: 0;
		margin-left: 15px;
		margin-right: 15px;
	}

	.darkHeader {
		padding: 0.5rem 1rem;
	}

	.darkHeader.navbar-light .navbar-nav .nav-link.active {
		color: #000 !important;
	}

	.feature {
		background-size: auto;
	}

	/* .feature-style li {
		margin-bottom: 30px;
	} */

	.feature-style li:last-child {
		margin-bottom: 0;
	}

	.feature-style p {
		margin: 0 auto;
		line-height: 20px;
	}

	.price {
		background-size: contain;
		background-position: top;
	}

	.contact {
		background-position: center;
	}

	.footer {
		padding-bottom: 0 !important;
	}

	.mrp h2 {
		font-size: 28px;
		margin-top: 20px;
	}

	.mrp h6.user-type {
		margin: 20px;
	}

	.price-year {
		margin-bottom: 20px;
	}

	.md-margin-top {
		margin-top: 20px !important;
	}

	h2 {
		font-size: 22px;
		margin-top: 0;
		margin-bottom: 25px;
	}

	h2.title {
		margin-top: 0;
		line-height: 1;
		text-align: left;
	}

	h1 {
		font-size: 40px;
		margin: 20px auto;
	}

	h4 {
		font-size: 13px;
	}

	p {
		font-size: 13px;
		line-height: 20px;
	}

	p.caption-about {
		text-align: left;
	}

	p.slide-cap-desc {
		font-size: 14px;
	}

	.home-right {
		width: 100%;
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}

	.about-decor .about-circle1 {
		left: auto;
		right: -50px;
		top: auto;
	}

	.about-decor .about-circle2 {
		right: -50px;
	}

	.screenshot-decor .screenshot-circle1 {
		right: 23%;
	}

	.screenshot-decor .screenshot-circle2 {
		right: 15%;
	}

	.feature-phone {
		display: none;
	}

	.d-medium-none-lg {
		display: none;
	}

	.mobile-light-left {
		left: 30%;
	}

	.mobile-light-right {
		right: 30%;
	}

	@-webkit-keyframes highlight-left {
		0% {
			opacity: 0;
			top: 350px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -140px;
		}
	}

	@keyframes highlight-left {
		0% {
			opacity: 0;
			top: 350px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -140px;
		}
	}

	@-webkit-keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 350px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	@keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 350px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	.team .team-circle2 {
		top: auto;
		left: -10px;
		bottom: 150px;
	}

	.team .team-circle3 {
		top: 50%;
		left: auto;
		right: 0;
	}

	.blog-page section {
		padding-bottom: 50px;
		padding-top: 50px;
	}

	.leave-coment {
		margin-top: 0;
	}

	.blog-text h5 {
		line-height: 1.6;
	}

	.md-height {
		height: 100%;
	}

	.page-margin {
		margin-top: 100px;
	}

	.d-align-center {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.reply-comment>div+div {
		margin-top: 20px;
	}

	.details-sidebar {
		margin-top: 100px;
	}

	.home-two {
		background-position: 30%;
		background-size: cover;
	}

	.navbarSupportedContent {
		color: #777777;
	}

	.home-style-two .navbar-light .navbar-nav .nav-link {
		color: #777777;
	}

	.home-style-two .darkHeader .navbar-nav .active>.nav-link {
		color: #18e7d3;
	}

	.img-scroll {
		left: 19px;
		top: 11px;
		width: calc(100% - 38px);
		height: calc(100% - 39px);
	}

	.pc-bg {
		width: 400px;
		height: 230px;
	}

	.mt-70 {
		text-align: center;
	}

	.blog-detail .md-fgrup-margin {
		margin-bottom: 25px;
	}

	.blog-detail .blog-text h3 {
		font-size: 16px;
	}

	.home .container {
		margin-top: 0 !important;
	}

	.nav-item.dropdown .dropdown-menu {
		-webkit-box-shadow: none;
		box-shadow: none;
		padding: 0 0 0 15px;
	}

	.dropdown-menu .nav-item {
		margin-left: 0;
	}

	#mymenu .dropdown-menu {
		display: none;
		-webkit-transition: 0.5s ease;
		transition: 0.5s ease;
	}

	#mymenu .dropdown-menu.show {
		display: block;
		-webkit-transition: 0.5s ease;
		transition: 0.5s ease;
	}

	#mymenu .dropdown-menu .nav-item {
		margin-left: 0;
	}

	.navbar-light .navbar-nav .nav-link {
		color: #777777;
	}

	.price-feature li {
		padding: 5px;
	}

	.about-text p {
		padding-left: 5px;
	}

	.about-style li {
		margin-bottom: 20px;
	}

	.feature-subtitle h3 {
		margin-top: 0;
	}

	.feature-decor .feature-circle1 {
		top: 570px;
		right: 13px;
	}

	.team-social li {
		margin: 0 3px;
	}

	.team-block .team-social li a {
		padding: 7px;
	}

	.team-carousel.rounded-dots .slick-dots {
		position: relative;
		top: 20px;
	}

	.blog-carousel.rounded-dots .slick-dots {
		position: relative;
		top: 20px;
	}

	.price-carousel.rounded-dots .slick-dots {
		position: relative;
		top: 10px;
	}

	.price-block:hover {
		-webkit-transform: unset;
		transform: unset;
	}

	.contact-right img {
		top: 0;
	}

	.blog-sec .blog-list>div+div .blog-item {
		margin-top: 40px;
	}

	.blog-description p {
		font-size: 14px;
	}

	.testimonial-carousel.rounded-dots .slick-dots {
		position: absolute;
		bottom: 40px;
		left: 0;
		right: 0;
	}

	.home-style nav.navbar .navbar-nav .nav-link {
		color: #777777;
	}

	.home-style nav.navbar .navbar-nav .nav-link:hover {
		color: white;
	}

	.home-style nav.darkHeader .navbar-nav .active>.nav-link {
		color: #18e7d3;
	}

	.features img {
		width: 80px;
	}

	.mt-35>div+div {
		margin-top: 70px;
	}

	/*404 page*/
	.error-wrapper .error-heading .cloud-second {
		margin-top: -100px;
	}

	.error-wrapper .error-heading .cloud-second .cloud-first {
		width: 700px;
	}

	.error-wrapper .error-heading .cloud-second .headline {
		font-size: 235px;
	}

	.margin-bottom>div+div+div .review-box {
		margin-top: 30px;
	}
}

@media (max-width: 768px) {
	body {
		overflow-x: hidden;
	}

	.full-page {
		overflow: auto;
	}

	.home {
		min-height: 100vh;
	}

	.mobile-light-right {
		top: -150px;
	}

	@-webkit-keyframes highlight-left {
		0% {
			opacity: 0;
			top: 330px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -90px;
		}
	}

	@keyframes highlight-left {
		0% {
			opacity: 0;
			top: 330px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -90px;
		}
	}

	@-webkit-keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 490px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	@keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 320px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	.testimonial-block {
		padding-top: 80px;
		padding-bottom: 30px;
	}

	.home-two {
		background-position: top;
		background-color: #19d6dc;
	}

	.features-img {
		width: 250px;
	}

	.screenshot-decor .screenshot-circle3 {
		left: 0;
		bottom: 0;
	}

	.testimonial-carousel.owl-carousel button.owl-prev {
		left: -20px;
	}

	.testimonial-carousel.owl-carousel button.owl-next {
		right: -20px;
	}

	.footer-icon img {
		width: 60%;
		margin-left: 8px;
	}

	.features li:nth-child(even) {
		border-right: none;
	}

	.features li:nth-child(13) {
		border-bottom: 1px solid #dddddd;
	}

	.features li:nth-child(14) {
		border-bottom: 1px solid #dddddd;
	}

	.features li:last-child {
		border-bottom: none;
	}

	.error-wrapper .error-heading {
		margin-top: 100px;
	}

	.error-wrapper .error-heading .cloud-first {
		width: 100%;
		margin-top: 0px;
	}

	.error-wrapper .error-heading .headline {
		font-size: 235px;
		margin-top: -130px;
	}

	.error-wrapper .error-heading .cloud-second {
		margin-top: -90px;
	}

	.error-wrapper .sub-content {
		margin-top: 100px;
	}

	/*Sign up*/
	.theme-form .form-control {
		-webkit-box-shadow: 0 0 5px 0 #18e7d3;
		box-shadow: 0 0 5px 0 #18e7d3;
	}

	.theme-form .form-control {
		-webkit-box-shadow: 0 0 5px 0 #18e7d3;
		box-shadow: 0 0 5px 0 #18e7d3;
	}
}

@media (max-width: 767px) {
	.faq .card-body {
		font-size: 13px;
	}

	.mob-mocup {
		width: 280px;
		left: 128px;
	}

	.testimonial-carousel .owl-dots {
		bottom: 60px;
	}

	.home-three {
		background-color: #19d8db;
	}

	.home-three .contain {
		position: relative;
		top: unset;
		left: unset;
		margin-right: auto;
		-webkit-transform: none;
		transform: none;
	}

	.animated-circle.three img {
		left: 0;
	}

	.home-two .contain {
		position: relative;
		margin-right: auto;
		top: auto;
		left: auto;
		-webkit-transform: none;
		transform: none;
	}

	.animated-circle:after {
		-webkit-animation: none;
		animation: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.home-contain {
		margin-top: 0;
	}

	.leave-coment h3.text-center {
		text-align: left !important;
	}

	.leave-coment .footer-form input::-webkit-input-placeholder {
		text-align: left;
	}

	.leave-coment .footer-form textarea::-webkit-input-placeholder {
		text-align: left;
	}

	.leave-coment .footer-form .form-control {
		text-align: left;
	}

	.garb-img {
		text-align: center;
		margin-top: 30px;
	}

	.price-type {
		margin: 0 auto;
	}

	.testimonial {
		background-size: contain;
	}

	.testimonial-avtar {
		height: 100px;
		width: 100px;
	}

	.testimonial-avtar:before {
		height: 100px;
		width: 100px;
	}

	.testimonial-avtar:after {
		height: 100px;
		width: 100px;
	}

	.testimonial-avtar img {
		height: 70px;
	}

	.testimonial-text {
		padding: 60px 20px 20px;
	}

	.testimonial-text p {
		font-size: 14px;
	}

	.testimonial-text h3 {
		line-height: 1;
		margin-top: 20px;
	}

	.d-medium-none-lg {
		display: none;
	}

	.price-block {
		padding: 30px;
	}

	.price-block .btn-custom {
		width: 50%;
	}

	.breadcrumb-bg {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.d-medium-none {
		display: none;
	}

	.mobile-light-left {
		left: 20%;
	}

	.mobile-light-right {
		right: 21%;
	}

	.home-right {
		display: none;
	}

	.home-contain {
		text-align: center;
	}

	.home-contain>div {
		width: 100%;
	}

	.home-decor .home-circle2 {
		right: -76px;
	}

	.home-decor .home-circle3 {
		top: 211px;
		right: -47px;
	}

	.about-phone {
		display: none;
	}

	.index-footer {
		bottom: unset;
		padding-bottom: 0;
	}

	.md-fgrup-margin {
		margin-bottom: 25px;
	}

	.blog-bradcrumb {
		float: unset;
		padding-bottom: 5px;
	}

	.blog-bradcrumb .breadcrumb {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 10px;
		padding-bottom: 0;
	}

	.blog-bradcrumb li {
		line-height: 1.5;
	}

	.blog-bradcrumb li a {
		line-height: 1.5;
	}

	.col-text-center {
		text-align: center;
	}

	.d-align-center {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.blog-page.blog-detail .media h5 {
		max-width: 100%;
	}

	.blog-cat-detail .marg-15 {
		margin: 10px auto;
	}

	.blog-cat-detail ul li a {
		font-size: 14px;
	}

	@-webkit-keyframes highlight-left {
		0% {
			opacity: 0;
			top: 380px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -120px;
		}
	}

	@keyframes highlight-left {
		0% {
			opacity: 0;
			top: 380px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -120px;
		}
	}

	@-webkit-keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 370px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	@keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 370px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	.team-block {
		padding: 30px;
	}

	.md-height {
		height: 200px;
	}

	.list-sidebar {
		margin-top: 50px;
	}

	.mb-xs-4 {
		margin-bottom: 1.5rem;
	}

	.animated-circle:before {
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 40px;
		height: 40px;
		bottom: 50px;
	}

	.animated-circle:after {
		left: 0;
		right: 0;
		margin: 0 auto;
		bottom: 50px;
	}

	.animated-circle.three:before {
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.animated-circle.three:after {
		display: none;
	}

	@-webkit-keyframes anim-effect-back {
		0% {
			-webkit-box-shadow: 0 0 0 20px #e0e0e0;
			box-shadow: 0 0 0 20px #e0e0e0;
			opacity: 1;
		}

		50% {
			-webkit-box-shadow: 0 0 0 50px #e0e0e0;
			box-shadow: 0 0 0 50px #e0e0e0;
			opacity: 1;
		}

		100% {
			-webkit-box-shadow: 0 0 0 70px #e0e0e0;
			box-shadow: 0 0 0 70px #e0e0e0;
			opacity: 1;
		}
	}

	@keyframes anim-effect-back {
		0% {
			-webkit-box-shadow: 0 0 0 20px #e0e0e0;
			box-shadow: 0 0 0 20px #e0e0e0;
			opacity: 1;
		}

		50% {
			-webkit-box-shadow: 0 0 0 50px #e0e0e0;
			box-shadow: 0 0 0 50px #e0e0e0;
			opacity: 1;
		}

		100% {
			-webkit-box-shadow: 0 0 0 70px #e0e0e0;
			box-shadow: 0 0 0 70px #e0e0e0;
			opacity: 1;
		}
	}

	@-webkit-keyframes anim-effect-front {
		0% {
			-webkit-box-shadow: 0 0 0 15px #e0e0e0;
			box-shadow: 0 0 0 15px #e0e0e0;
			opacity: 1;
		}

		50% {
			-webkit-box-shadow: 0 0 0 30px rgba(224, 224, 224, 0.6);
			box-shadow: 0 0 0 30px rgba(224, 224, 224, 0.6);
			opacity: 1;
		}

		100% {
			-webkit-box-shadow: 0 0 0 40px rgba(224, 224, 224, 0);
			box-shadow: 0 0 0 40px rgba(224, 224, 224, 0);
			opacity: 0;
		}
	}

	@keyframes anim-effect-front {
		0% {
			-webkit-box-shadow: 0 0 0 15px #e0e0e0;
			box-shadow: 0 0 0 15px #e0e0e0;
			opacity: 1;
		}

		50% {
			-webkit-box-shadow: 0 0 0 30px rgba(224, 224, 224, 0.6);
			box-shadow: 0 0 0 30px rgba(224, 224, 224, 0.6);
			opacity: 1;
		}

		100% {
			-webkit-box-shadow: 0 0 0 40px rgba(224, 224, 224, 0);
			box-shadow: 0 0 0 40px rgba(224, 224, 224, 0);
			opacity: 0;
		}
	}

	.margin-top-bottom {
		margin-top: 50px;
	}

	.demo-description {
		line-height: 20px;
	}

	.sm-d-none {
		display: none;
	}

	.coming-title {
		font-size: 22px;
	}

	.landing-title {
		margin-bottom: 0;
	}

	.border-shape-two {
		margin: 20px auto 10px;
	}

	.tapl-title {
		font-size: 24px;
	}

	.center-sm {
		text-align: center;
	}

	.price-feature li {
		padding: 5px;
	}

	.about-style li {
		margin-bottom: 30px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: flex-start;
		text-align: left;
	}

	.about-text p {
		text-align: left;
	}

	.testimonial-decor .testi-circle2 {
		bottom: 0;
	}

	.testimonial-carousel.owl-carousel button.owl-prev {
		left: -40px;
	}

	.testimonial-carousel.owl-carousel button.owl-next {
		right: -40px;
	}

	.about-contain>div {
		width: 100%;
		text-align: center;
	}

	.feature-decor .feature-circle1 {
		display: none;
	}

	.team .team-circle3 {
		display: none;
	}

	.blog-list>div+div .blog-item {
		margin-top: 40px;
	}

	.blog-text h6 {
		margin-top: 15px;
	}

	.blog-page .media h5 {
		font-size: 13px;
	}

	.video-play iframe {
		height: 280px;
	}

	.play-button img {
		left: 0;
		right: 0;
		margin: 0 auto;
		bottom: 30px;
		width: 80px;
	}

	.mt-35>div+div {
		margin-top: 40px;
	}

	.testimonial-avtar:after {
		display: none;
	}

	.testimonial-avtar:before {
		display: none;
	}

	.margin-bottom>div+div+div .review-box {
		margin-top: 30px;
	}

	.margin-bottom>div+div .review-box {
		margin-top: 30px;
	}
}

@media (max-width: 600px) {
	.margin-bottom>div .review-box {
		padding: 20px 15px;
	}

	.testimonial-text {
		-webkit-box-shadow: 0 0 10px 0 rgba(170, 170, 170, 0.25);
		box-shadow: 0 0 10px 0 rgba(170, 170, 170, 0.25);
	}

	.landing-circle {
		display: none;
	}

	.reply-comment .media img {
		width: 70px;
	}

	.sm-mb {
		margin-bottom: 15px;
	}

	.home-decor .home-circle3 {
		display: none;
	}

	.home-decor .home-circle2 {
		right: -56px;
	}

	.about-phone {
		display: none;
	}

	.team .team-circle1 {
		display: none;
	}

	.team .team-circle2 {
		display: none;
	}

	.team .team-circle3 {
		display: none;
	}

	.list-sidebar {
		margin-top: 50px;
	}

	@-webkit-keyframes anim-effct-testi {
		0% {
			-webkit-box-shadow: 0 0 0 8px #e0e0e0;
			box-shadow: 0 0 0 8px #e0e0e0;
			opacity: 1;
		}

		50% {
			-webkit-box-shadow: 0 0 0 15px rgba(224, 224, 224, 0.6);
			box-shadow: 0 0 0 15px rgba(224, 224, 224, 0.6);
			opacity: 1;
		}

		100% {
			-webkit-box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
			box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
			opacity: 0;
		}
	}

	@keyframes anim-effct-testi {
		0% {
			-webkit-box-shadow: 0 0 0 8px #e0e0e0;
			box-shadow: 0 0 0 8px #e0e0e0;
			opacity: 1;
		}

		50% {
			-webkit-box-shadow: 0 0 0 15px rgba(224, 224, 224, 0.6);
			box-shadow: 0 0 0 15px rgba(224, 224, 224, 0.6);
			opacity: 1;
		}

		100% {
			-webkit-box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
			box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
			opacity: 0;
		}
	}

	@-webkit-keyframes anim-effct-testi-back {
		0% {
			-webkit-box-shadow: 0 0 0 8px #e0e0e0;
			box-shadow: 0 0 0 8px #e0e0e0;
			opacity: 1;
		}

		50% {
			-webkit-box-shadow: 0 0 0 15px #e0e0e0;
			box-shadow: 0 0 0 15px #e0e0e0;
			opacity: 1;
		}

		100% {
			-webkit-box-shadow: 0 0 0 25px #e0e0e0;
			box-shadow: 0 0 0 25px #e0e0e0;
			opacity: 1;
		}
	}

	@keyframes anim-effct-testi-back {
		0% {
			-webkit-box-shadow: 0 0 0 8px #e0e0e0;
			box-shadow: 0 0 0 8px #e0e0e0;
			opacity: 1;
		}

		50% {
			-webkit-box-shadow: 0 0 0 15px #e0e0e0;
			box-shadow: 0 0 0 15px #e0e0e0;
			opacity: 1;
		}

		100% {
			-webkit-box-shadow: 0 0 0 25px #e0e0e0;
			box-shadow: 0 0 0 25px #e0e0e0;
			opacity: 1;
		}
	}

	.home-style-two .modal.video-play .modal-dialog {
		-webkit-transform: translate(0%, 0);
		transform: translate(0%, 0);
	}

	.home-style .modal.video-play .modal-dialog {
		-webkit-transform: translate(0%, 0);
		transform: translate(0%, 0);
	}

	.video-play iframe {
		height: 280px;
		width: 480px;
	}

	.about-style li {
		margin-bottom: 25px;
	}

	p.caption-about {
		margin-bottom: 22px;
	}

	.owl-theme .owl-nav.disabled+.owl-dots {
		margin-top: 17px !important;
	}

	.price-carousel .owl-dots {
		margin-top: 17px !important;
	}

	.about-decor .about-circle2 {
		display: none;
	}

	.landing-circle2 img {
		display: none;
	}

	.about-contain>div {
		width: 100%;
		text-align: center;
	}

	.blog-text h3 {
		font-size: 14px;
	}

	.footer-text h2 {
		margin: 20px;
	}

	.animated-circle.three img {
		bottom: 20px;
	}

	.animated-circle.three:before {
		bottom: 38px;
	}

	.animated-circle.three:after {
		bottom: 38px;
	}
}

@media (max-width: 576px) {
	.clock-box li {
		width: 50px;
		margin: 0px 5px 20px;
	}

	.clock-box li span {
		font-size: 22px;
		width: 50px;
		height: 50px;
	}

	.not-found .error-font {
		font-size: 120px;
		letter-spacing: -22px;
	}

	.not-found .mt-5 {
		margin-top: 35px !important;
	}

	.download {
		padding-bottom: 0;
		min-height: auto;
	}

	.download .innerpage-decor {
		display: none;
	}

	.download .downlod-img {
		margin-bottom: 20px;
		height: 125px;
	}

	.download h3 {
		margin-top: 0;
	}

	.authentication-form .theme-form .form-control {
		padding: 12px 20px;
	}

	.authentication-form .social-btns .fb {
		margin-bottom: 10px;
	}

	.authentication-form .card {
		padding: 15px;
		width: calc(100% - 30px);
	}

	.authentication-form p {
		width: 90%;
		margin-bottom: 15px;
	}

	.testimonial-block {
		margin-top: -20px;
	}

	.testimonial-carousel .owl-dots {
		bottom: 30px;
	}

	.mob-mocup {
		display: none;
	}

	.screenshot-item {
		border: 2px solid #ddd;
		border-radius: 10px;
		overflow: hidden;
	}

	section,
	.address-bar {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.testimonial-text {
		margin-bottom: 50px;
	}

	.footer {
		padding-top: 50px;
	}

	.marg-20 {
		margin: 7px auto;
	}

	.mb-xs-4 {
		margin-bottom: 15px;
	}

	@-webkit-keyframes highlight-left {
		0% {
			opacity: 0;
			top: 370px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -120px;
		}
	}

	@keyframes highlight-left {
		0% {
			opacity: 0;
			top: 370px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -120px;
		}
	}

	@-webkit-keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 350px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	@keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 350px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	.features-img {
		width: 214px;
	}

	.coming-title {
		font-size: 18px;
		line-height: 1.5;
	}

	.tapl-title {
		font-size: 18px;
	}

	.rating-star img {
		margin-right: 15px;
		width: 40px;
	}

	h2.title {
		letter-spacing: 2px;
	}

	.feature h2 {
		font-size: 20px;
	}

	.home-contain p {
		line-height: 18px;
		padding: 0 15px;
	}

	.footer-text p {
		line-height: 18px;
	}

	.blog-list>div+div .blog-item {
		margin-top: 40px;
	}

	.theme-form .form-group {
		margin: 15px auto;
	}

	.video-play iframe {
		height: 280px;
	}

	section .about-contain {
		-webkit-animation: none;
		animation: none;
	}

	section .about-phone {
		-webkit-animation: none;
		animation: none;
	}

	section .feature-phone {
		-webkit-animation: none;
		animation: none;
	}

	section .screenshot-contain {
		-webkit-animation: none;
		animation: none;
	}

	section .price-block {
		-webkit-animation: none;
		animation: none;
	}

	section .contact-right {
		-webkit-animation: none;
		animation: none;
	}

	section .contact-us {
		-webkit-animation: none;
		animation: none;
	}

	section .footer-text {
		-webkit-animation: none;
		animation: none;
	}

	section .contact-subscribe {
		-webkit-animation: none;
		animation: none;
	}

	section .feature-style li:nth-child(1) {
		-webkit-animation: none;
		animation: none;
	}

	section .feature-style li:nth-child(2) {
		-webkit-animation: none;
		animation: none;
	}

	section .feature-style li:nth-child(3) {
		-webkit-animation: none;
		animation: none;
	}

	section .team-carousel .team-item {
		-webkit-animation: none;
		animation: none;
	}

	section .blog-carousel .blog-item {
		-webkit-animation: none;
		animation: none;
	}

	section .testimonial-carousel .testimonial-item {
		-webkit-animation: none;
		animation: none;
	}

	section .bottom-section .footer-style {
		-webkit-animation: none;
		animation: none;
	}

	section .map iframe {
		-webkit-animation: none;
		animation: none;
	}

	.team-block:hover .team-social li:nth-child(1) {
		-webkit-animation: none;
		animation: none;
	}

	.team-block:hover .team-social li:nth-child(2) {
		-webkit-animation: none;
		animation: none;
	}

	.team-block:hover .team-social li:nth-child(3) {
		-webkit-animation: none;
		animation: none;
	}

	.team-block:hover .team-social li:nth-child(4) {
		-webkit-animation: none;
		animation: none;
	}

	.team-block:hover .team-social li:nth-child(5) {
		-webkit-animation: none;
		animation: none;
	}

	.theme-form .form-control {
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 20px;
	}

	.about-text h3 {
		line-height: 22px;
		font-size: 14px;
	}
}

@media (max-width: 575.98px) {
	.about-style li {
		min-height: auto;
	}

	.screenshot {
		background-size: cover;
	}

	.home-style-two .modal.video-play .modal-dialog {
		-webkit-transform: translate(0%, 0);
		transform: translate(0%, 0);
	}

	.home-style .modal.video-play .modal-dialog {
		-webkit-transform: translate(0%, 0);
		transform: translate(0%, 0);
	}
}

@media (max-width: 480px) {
	.testimonial-carousel.owl-theme .owl-nav.disabled+.owl-dots {
		position: absolute;
		top: 80%;
	}

	.home-two {
		background-position: 35%;
		background-size: cover;
	}

	.mobile-light-right {
		right: 20%;
	}

	@-webkit-keyframes highlight-left {
		0% {
			opacity: 0;
			top: 310px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -100px;
		}
	}

	@keyframes highlight-left {
		0% {
			opacity: 0;
			top: 310px;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			top: -100px;
		}
	}

	@-webkit-keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 300px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	@keyframes highlight-right {
		0% {
			opacity: 0;
			bottom: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		70% {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			opacity: 0;
			top: 300px;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
	}

	.pc-bg {
		width: 270px;
		height: 150px;
	}

	.img-scroll {
		left: 12px;
		top: 7px;
		width: calc(100% - 29px);
		height: calc(100% - 23px);
	}

	.landing-caption {
		line-height: 1.2 !important;
	}

	.testimonial-block {
		padding-bottom: 30px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.feature-subtitle h3 {
		font-size: 14px;
		letter-spacing: 0;
		line-height: 18px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin: 0;
	}

	.feature-style p {
		line-height: 18px;
	}

	.team-carousel .owl-dots {
		top: -54px;
	}

	.breadcrumb-bg h2 {
		font-size: 18px;
	}

	.blog-sec .blog-list>div+div .blog-item {
		margin-top: 30px;
	}

	.rounded-dots .slick-dots {
		margin-top: 15px;
		position: relative;
		left: 0;
		right: 0;
		top: 0;
		bottom: auto;
	}

	.price-block .btn-custom {
		width: auto;
	}

	.video-play iframe {
		height: 250px;
		width: auto;
	}

	img.main-logo {
		width: 60%;
	}

	.features li {
		padding: 20px 0;
	}

	.features li:nth-child(15) {
		border-bottom: 1px solid #dddddd;
	}

	.testimonial-text p {
		font-size: 12px;
	}

	.testimonial-carousel.owl-carousel button.owl-prev {
		left: 0;
		position: relative;
	}

	.testimonial-carousel.owl-carousel button.owl-next {
		right: 0;
		position: relative;
	}

	.coming-soon .footer-text .footer-form .form-control {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.custom-row>div,
	.custom-row .col-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.custom-row .theme-link {
		text-align: left !important;
	}
}

@media (max-width: 480px) and (max-width: 479.5px) {
	.features li {
		border-right: none;
	}
}

@media (max-width: 480px) and (max-width: 400px) {
	h1 {
		font-size: 34px;
	}

	.feature-subtitle {
		margin-bottom: 5px;
	}

	.mobile-light-left {
		display: none;
	}

	.mobile-light-right {
		display: none;
	}

	.testi-circle1 {
		display: none;
	}

	.testi-circle2 {
		display: none;
	}

	.coming-title {
		margin-top: 50px;
	}

	.tap-top {
		width: 40px;
		height: 40px;
		line-height: 1.7;
	}

	.feature-style p {
		font-size: 12px;
	}

	.testimonial-carousel.owl-carousel button.owl-next {
		display: none;
	}

	.testimonial-carousel.owl-carousel button.owl-prev {
		display: none;
	}

	.team-block .team-social li a {
		padding: 5px;
	}

	.video-play iframe {
		height: 200px;
	}

	.footer-text h2 {
		margin: 10px auto;
		font-size: 17px;
	}

	.features li {
		border-right: none;
	}

	.mt-35>div+div {
		margin-top: 50px;
	}

	.rating-star img {
		margin-right: 10px;
		width: 20px;
	}
}

@media (max-width: 480px) and (max-width: 360px) {
	.blog-list>div+div .blog-item {
		margin-top: 30px;
	}

	.blog-page.blog-detail .media h5 {
		max-width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.blog-page.blog-detail .media p {
		font-size: 13px;
	}

	.feature-subtitle h3 {
		line-height: 15px;
	}

	.feature-subtitle p {
		font-size: 9px;
	}

	.home-decor .home-circle1 {
		left: 50px;
	}

	.home-decor .home-circle2 {
		right: -62px;
		top: 100px;
	}

	.marg-20 {
		margin: 5px auto;
	}

	.blog-text h6 {
		margin-top: 10px;
		font-size: 11px;
	}

	.blog-text h5 {
		font-size: 11px;
	}

	.blog-text h3.blog-head {
		font-size: 14px;
	}

	.testimonial-text h3 {
		font-size: 15px;
		margin-top: 0;
	}

	.testimonial-text h6 {
		line-height: 2;
	}

	.team-block {
		padding: 5px;
	}

	.blog-sec .blog-list>div+div .blog-item {
		margin-top: 30px;
	}

	.recent-blog .media p {
		font-size: 11px;
	}
}

@media (max-width: 480px) and (max-width: 340px) {
	.footer-form {
		padding: 0;
	}

	.team-social li {
		margin: 0 2px;
	}

	.footer-address a {
		padding-left: 54px;
		line-height: 18px;
	}

	.footer-icon img {
		width: 30%;
		margin-left: 30px;
	}
}

@media (max-width: 480px) and (max-width: 320px) {
	.home-three .play-button {
		margin-top: 70px;
	}

	.animated-circle:before {
		bottom: 20px;
	}

	.play-button img {
		bottom: 0;
	}
}


/* New Css by ketan */
/* feature css */
.fontforHeading3 {
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 2.5rem;
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 0%;
	text-transform: capitalize;
	letter-spacing: 1px;
	word-spacing: 2px;
}

.fontforHeading4 {
	font-size: 1.6rem;
	text-transform: none;
	font-weight: 700;
	
}

.fontforHeading4,
.fontforHeadingg {
	font-weight: 500;
	line-height: 1.6rem;
	margin-right: 10%;
	letter-spacing: 1px;
	word-spacing: 2px;
	font-weight: 1000;
}

.Table {
	margin-left: 10%;
	margin-right: 10%;
}

.pricingConetnt {
	font-size: 1rem;
	font-weight: 500;
	border-radius: 0.25rem;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
	color: #18e7d3;
}

.marginBottomZerom {
	margin-bottom: 0;
}

.paddingleftBy10 h1 {
	margin-bottom: 0;
	margin-top: 0;
}

.marginBottom10 {
    margin-bottom: 20px;
}

.fontSizeHandline {
	font-size: .9rem;
	color: #18e7d3;
	text-decoration: underline;
}

.fontSizeHandline:hover {
	color: blue;
}

.tenPixelFontSize {
	font-size: 10px
}

.fourteenPixelFontSizw {
	font-size: 14px
}

/* Table css */

.TableHeading,
.TableHeading1 {
	border-radius: .25rem;
	text-align: center;
	padding: .5rem .5rem 0;
	border: 1px solid #fff;
	color: #fff;
	font-size: 2rem;
	line-height: 3rem;
}

.TableHeading {
	background: linear-gradient(180deg, #19d3dc, #18e7d3, #18e7d3, #18e7d3);
	font-weight: 500;
}

.featuresTableElemet {
	font-size: 16px;
	font-weight: 500;
	line-height: 1rem;
	margin-bottom: .2rem;
}

#greenColor {
	color: green;
	font-weight: 600;
}

.header-class {
	visibility: hidden
}


td {
	font-size: 16px;
	font-weight: 300;
	width: 35%
}

.demo-key-row {
	width: 33%;
	text-align: center
}

.backgroundfootercolor {
	background-image: linear-gradient(90deg, #19d3dc, #18e7d3, #18e7d3, #19d2dd)
}

.backgrcolor2 {
	background-image: linear-gradient(90deg, #eeeff0, #f0f6fc, #f3f8fd, #eeeff0)
}

.margin-fot {
	margin-bottom: 20px;
	margin-top: -70px
}

.fontforHeading2 {
	font-size: 2rem;
	line-height: 2.5rem;
	margin-left: 10%;
	margin-right: 10%;
	text-transform: capitalize;
	letter-spacing: 1px;
	word-spacing: 2px
}

.fontforHeading2 {
	font-weight: 500
}

.fontforHeading3 {
	color: #fff;
	font-size: 1.5rem;
	line-height: 2.5rem;
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 0%;
	text-transform: capitalize;
	letter-spacing: 1px;
	word-spacing: 2px
}

.fontforHeading3 {
	font-weight: 500
}



.alignShareItem {
	text-align: center
}

fontweight {
	font-weight: 200
}

.portfolio-icon2 {
	width: 80px;
	margin-bottom: 20px
}

.blog-block2,
.blog-block3 {
	width: 100%;
	height: auto;
	margin-right: 10%
}

.blog-box2:hover,
.blog-box3:hover {
	box-shadow: 0 .5rem 1rem 0 #c7c9ca;
	border-bottom: 4px solid #388dd3
}

.conetent {
	-webkit-justify-content: center;
	justify-content: center
}

.imagesizew {
	height: 200;
	width: 200
}

.fontheadingSize {
	font-size: 1.3rem;
	color: #000
}

.fontcolorblack {
	color: #000
}

.blog-box {
	padding: 5px
}

.blog-box,
.blog-box2 {
	background-color: #fff
}

.blog-box2 {
	padding: 20px
}

.blog-box3 {
	padding: 5px;
	background-color: #fff
}

.blog-box img {
	opacity: 1;
	transition: .3s ease-in-out;
	overflow: hidden
}

.blog-text h3 {
	margin-top: 15px;
	line-height: 24px;
	font-size: 17px;
	margin-bottom: 15px
}

.blog-text p {
	font-size: 14px;
	margin-top: 15px
}

.blog-text h5 {
	margin: 0;
	line-height: 1;
	text-transform: capitalize;
	display: inline-block;
	font-weight: 600
}

.blog-text h6 {
	margin-top: 20px;
	margin-bottom: 0
}

.text-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
	text-align: justify !important
}

.text-wrap {
	white-space: normal !important
}

.text-nowrap {
	white-space: nowrap !important
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.text-left {
	text-align: left !important
}

.text-right {
	text-align: right !important
}

.text-center {
	text-align: center !important
}

@media (min-width:576px) {
	.text-sm-left {
		text-align: left !important
	}

	.text-sm-right {
		text-align: right !important
	}

	.text-sm-center {
		text-align: center !important
	}
}

@media (min-width:768px) {
	.text-md-left {
		text-align: left !important
	}

	.text-md-right {
		text-align: right !important
	}

	.text-md-center {
		text-align: center !important
	}
}

@media (min-width:992px) {
	.text-lg-left {
		text-align: left !important
	}

	.text-lg-right {
		text-align: right !important
	}

	.text-lg-center {
		text-align: center !important
	}
}

@media (min-width:1200px) {
	.text-xl-left {
		text-align: left !important
	}

	.text-xl-right {
		text-align: right !important
	}

	.text-xl-center {
		text-align: center !important
	}
}

.text-lowercase {
	text-transform: lowercase !important
}

.text-uppercase {
	text-transform: uppercase !important
}

.text-capitalize {
	text-transform: capitalize !important
}

.font-weight-light {
	font-weight: 300 !important
}

.font-weight-lighter {
	font-weight: lighter !important
}

.font-weight-normal {
	font-weight: 400 !important
}

.font-weight-bold {
	font-weight: 700 !important
}

.font-weight-bolder {
	font-weight: bolder !important
}

.font-italic {
	font-style: italic !important
}

.text-white {
	color: #fff !important
}

.text-primary {
	color: #007bff !important
}

a.text-primary:focus,
a.text-primary:hover {
	color: #0056b3 !important
}

.text-secondary {
	color: #6c757d !important
}

a.text-secondary:focus,
a.text-secondary:hover {
	color: #494f54 !important
}

.text-success {
	color: #28a745 !important
}

a.text-success:focus,
a.text-success:hover {
	color: #19692c !important
}

.text-info {
	color: #17a2b8 !important
}

a.text-info:focus,
a.text-info:hover {
	color: #0f6674 !important
}

.text-warning {
	color: #ffc107 !important
}

a.text-warning:focus,
a.text-warning:hover {
	color: #ba8b00 !important
}

.text-danger {
	color: #dc3545 !important
}

a.text-danger:focus,
a.text-danger:hover {
	color: #a71d2a !important
}

.text-light {
	color: #f8f9fa !important
}

a.text-light:focus,
a.text-light:hover {
	color: #cbd3da !important
}

.text-dark {
	color: #343a40 !important
}

a.text-dark:focus,
a.text-dark:hover {
	color: #121416 !important
}

.text-body {
	color: #212529 !important
}

.text-muted {
	color: #6c757d !important
}

.text-black-50 {
	color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
	color: hsla(0, 0%, 100%, .5) !important
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0
}

.text-decoration-none {
	text-decoration: none !important
}

.text-break {
	word-break: break-word !important;
	overflow-wrap: break-word !important
}

.text-reset {
	color: inherit !important
}

.visible {
	visibility: visible !important
}

.invisible {
	visibility: hidden !important
}


.team-box {
	background-color: #fff;
	padding: 30px
}

.team-box .overlay {
	position: relative
}

.team-box .team-avtar img {
	height: 170px;
	width: 170px !important;
	border-radius: 100%;
	margin: 0 auto
}

.team-box2 {
	background-color: #fff;
	padding: 30px
}

.team-box2 .overlay {
	position: relative
}

.team-box2 .team-avtar img {
	height: 120px;
	width: 120px !important;
	margin: 0 auto
}

.team-box3 {
	background-color: #fff;
	padding: 30px
}

.team-box3 .overlay {
	position: relative
}

.team-box3 .team-avtar img {
	height: 400px;
	width: 500px !important;
	margin: 0 auto
}

.team-text h3 {
	text-transform: uppercase;
	letter-spacing: .1em;
	margin-top: 30px;
	margin-bottom: 13px
}

.team-text h3,
.team-text h6 {
	text-align: center;
	line-height: 1
}

.team-text h6 {
	margin-top: 0;
	margin-bottom: 15px
}

.team-social {
	position: absolute;
	padding-left: 0;
	-webkit-align-items: center;
	align-items: center;
	margin: 0;
	transition: .3s ease-in-out;
	padding-bottom: 0
}

.team-social>li {
	margin: 0 4px;
	display: inline-block
}

.team-social li a:hover i {
	color: #fff
}

.team-carousel {
	margin-top: 12px
}

.team-carousel button:focus {
	outline: 0
}

/* footer css */

.footer-text {
	text-align: center;
}

.footer-text h2 {
	margin: 25px auto;
}

.footer-text p {
	padding-left: 50px;
	padding-right: 50px;
	margin-bottom: 25px;
}

.footer-text .footer-form {
	padding: 0;
}

.footer-text .footer-form .form-control {
	border-radius: 50px;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: 25px;
}

.footer-form {
	padding-left: 50px;
	padding-right: 50px;
}

.footer-form .form-group {
	margin-bottom: 20px;
}

.footer-form .btn-custom {
	padding: 10px 40px;
}

.footer-form .form-button {
	text-align: center;
}

.footer-form .form-control {
	border-radius: 35px;
	text-transform: uppercase;
	padding-top: 15px;
	padding-bottom: 15px;
	border: 2px solid #eee;
	font-size: 12px;
	text-align: center;
	height: auto;
}

.footer-form input::-webkit-input-placeholder {
	color: #aaa !important;
	font-size: 13px;
	text-align: center;
	letter-spacing: .1em;
}

.dataaAbout1 {
	text-align: left;
	margin-left: 5%;
	margin-right: 5%;
}

.margintopfotfooter {
	margin-top: 7%;
	margin-bottom: 15px;
	font-weight: 400;
	font-size: 1.2rem;
	color: #000;
}

.dataaAbout2 {
	text-align: left;
	margin-left: 10%;
	margin-right: 10px;
}

.fontweightf {
	font-weight: 200;
	color: #000;
	font-size: .9rem;
}

.blueBackground {
	background: linear-gradient(180deg, #2cc6ff 0, #21ecff);
}

.fontsizeabout {
	font-size: 1rem;
	color: #00f7ff;
	margin-bottom: 9px;
}

.address-bar,
.contactbgcolor {
	background: linear-gradient(#363535, #363535 0 100%);
}

.address-bar {
	padding-top: 100px;
	padding-bottom: 100px;
	margin: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.footer-icon {
	position: absolute;
	top: -3px;
}

.footer-style {
	padding: 0;
	margin: 0;
}

.footer-style li {
	position: relative;
}

.footer-style li+li {
	margin-top: 50px;
}

.footer-style li:last-child .footer-icon {
	margin-bottom: 0;
}

.bottom-section iframe {
	width: 100%;
	height: 100%;
	border: 0;
}

.footer-address a {
	padding-left: 35px;
	color: #fff !important;
	letter-spacing: .03em;
	line-height: 22px;
	margin-bottom: 0;
}

.copyright-section p {
	text-align: center;
	width: 100%;
	padding: 15px;
	margin-bottom: 0;
}

.color-grey {
	color: #777777;
  }
  
  .color-black {
	color: black;
  }
  

#navbar-main {
	width: 100%
}

ul {
	list-style: none
}

@media (max-width: 800px) {
	#responsivefontSize {
		font-size: 15px;
		line-height: 20px;
		margin-left: 0;
		margin-right: 0;
		text-align: justify;
	}

	#marginleftforweb {
		border-radius: 0;
		padding: 2%;
	}

	#marginleftforweb1 {
		word-spacing: 0.5rem;
		/* padding: 2%; */
		color: #fff;
		font-size: 1rem;
		text-transform: none;
	}

	#marginleftforweb1:hover {
		color: #fff;
	}

	.newcsssforplan {
		font-size: 24px;
		font-weight: 700;
	}

	.innersubpoints {
		margin-bottom: 3%;
	}
}

@media (min-width: 200px) and (max-width: 900px) {
	section {
		padding-top: 80px;
	}

	.feature-style li {
		margin-bottom: 2%;
	}

	.MuiGrid-spacing-xs-4>.MuiGrid-item {
		padding: 3%;
		margin-left: 15px;
		margin-right: 15px;
		text-align: justify;
	}

	.tenPixelFontSize {
		font-size: 10px;
	}

	.fourteenPixelFontSizw {
		font-size: 14px;
	}
}

@media (min-width: 200px) and (max-width: 400px) {
	#marginTopForContactUs {
		margin-top: 10%;
	}
}

.margintopforfeatures {
	margin-top: 5%;
}

.testimonial {
	background-size: cover;
}

.feature-subtitle h3 {
	padding-left: 40px;
	margin-top: 8px
}

.feature-icon i {
	color: #18e7d3
}


.insideul li {
	position: relative;
	margin-bottom: 10px;
	font-weight: 300
}

.innersubpoints {
	margin-bottom: 3%
}


.feature-style .fa {
	padding-right: 2%;
}

.innersubpoints li {
	margin-bottom: 0;
}

#FirstLevelSubpoint {
	font-weight: 500;
	font-size: 1rem;
}

.margintopforfeaturesli {
	padding-bottom: 6%;
}

#SecondLevelSubpoint {
	font-weight: 400;
	font-size: .9rem;
}

#thirdLevelSubpoint {
	font-weight: 300;
	font-size: .8rem;
}

#lowercase {
	text-transform: lowercase;
	margin-bottom: 0
}

#newIconFont {
	font-size: .6rem;
	color: green
}

#padding10 {
	padding-right: 1%
}

@media (max-width:992px) {
	.feature .feature-style li:last-child {
		margin-bottom: 30px
	}

	.feature .sm-m-top .feature-style li:last-child {
		margin-bottom: 0
	}

	.marginbottomsize {
		margin-bottom: 5%
	}

	.margintopforfeatures {
		margin-top: 10%
	}

	.testimonial {
		background-size: cover
	}

	.footer-text {
		margin-top: 0
	}

	.feature-phone {
		top: 100px
	}

	.feature-decor .feature-circle1,
	.team .team-circle3 {
		display: none
	}

	.contact-right img {
		width: 67%
	}

	section * {
		-webkit-animation: none !important;
		animation: none !important
	}

	.mobile-light-left,
	.mobile-light-right {
		display: none
	}

	.d-align-center {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center
	}
}

@media (max-width:1366px) {
	.home-right {
		width: 90%
	}

	.back-img .home-contain2 {
		margin-top: -60px
	}

	.home-contain2 {
		margin-top: 50px
	}

	.home-two {
		padding-bottom: 0;
		height: auto !important
	}

	.contact-right {
		width: 70%
	}

	.screenshot-contain {
		height: 500px
	}

	.about {
		background-position: 100%
	}

	.feature {
		background-position: bottom;
		min-height: 730px
	}

	.feature-phone img {
		height: 600px
	}

	.about-right {
		height: 500px;
		width: 500px
	}

	.home-right {
		width: 65%;
		float: right;
		position: absolute;
		right: 0;
		top: -50px
	}

	.home-decor .home-circle1 {
		top: 120px;
		left: 30px
	}

	.home-decor .home-circle2 {
		top: 130px;
		right: -30px
	}

	.home-decor .home-circle3 {
		top: 200px;
		right: 25px
	}

	.feature-phone {
		top: 126px;
		left: 50px
	}

	.feature-phone img {
		width: 65%
	}

	.team-block {
		padding: 20px
	}

	.team-block:hover .team-social li:first-child,
	.team-block:hover .team-social li:nth-child(2),
	.team-block:hover .team-social li:nth-child(3),
	.team-block:hover .team-social li:nth-child(4),
	.team-block:hover .team-social li:nth-child(5) {
		-webkit-animation: none;
		animation: none
	}

	.team .team-circle1 {
		top: auto;
		left: 60px
	}

	.team .team-circle2 {
		top: auto;
		left: 10px
	}

	.team .team-circle3 {
		right: 0;
		left: unset;
		bottom: 90px
	}

	.screenshot {
		background-size: unset
	}

	.screenshot-decor .screenshot-circle2 {
		top: 120px;
		right: 20%
	}

	.screenshot-decor .screenshot-circle1 {
		top: 110px;
		right: 30%
	}

	.screenshot-decor .screenshot-circle3 {
		left: 29%
	}

	.price-type {
		padding: 10px
	}

	.price-feature {
		margin: 20px auto
	}

	.blog-text h3 {
		margin-bottom: 10px
	}

	.blog-text h5 {
		font-size: 12px
	}

	.home-two {
		background-size: auto
	}

	.home-three {
		background-size: cover
	}

	.landing-caption {
		line-height: 1.5 !important
	}

	.pc-bg {
		width: 360px;
		height: 210px;
		background-size: contain
	}

	.img-scroll {
		width: calc(100% - 34px);
		height: calc(100% - 40px);
		left: 17px;
		top: 10px
	}

	.border-shape-two {
		width: 95%
	}

	.features-img {
		width: 284px
	}

	.coming-title {
		margin-top: 70px
	}

	.features {
		text-align: center
	}

	.features img {
		width: 100px
	}

	.features-title {
		font-size: 14px
	}

	.tapl-title {
		font-size: 32px
	}

	.home-style .modal.video-play .modal-dialog {
		-webkit-transform: translate(-20%);
		transform: translate(-20%)
	}

	.darkHeader.theme-nav .nav-item {
		padding: 20px 0
	}

	.home-contain2 p {
		line-height: 22px
	}

	.feature-decor .feature-circle1 {
		top: auto
	}

	.about-decor .about-circle1 {
		right: 0
	}

	.price-block .btn-custom {
		padding: 11px 25px
	}

	.testimonial-carousel.owl-carousel button.owl-prev {
		left: -80px
	}

	.testimonial-carousel.owl-carousel button.owl-next {
		right: -80px
	}

	.footer-form .form-control {
		padding-top: 15px;
		padding-bottom: 15px
	}

	.footer-text h2 {
		margin: 15px auto
	}

	iframe.video-play {
		width: 700px;
		height: 600px
	}

	.animated-circle.three img {
		bottom: 50px
	}

	.animated-circle.three:after,
	.animated-circle.three:before {
		bottom: 68px
	}

	img.main-logo {
		width: 100%
	}

	.landing-circle3 img {
		top: 0;
		left: 14px
	}

	.landing-circle2 img {
		top: 160px;
		left: 125px
	}

	.landing-circle1 img {
		top: 10px;
		left: 200px
	}
}

@media (max-width:1200px) {
	img.main-logo {
		width: unset
	}

	.home-contain2 {
		margin-top: 0
	}
}


.home2font {
	font-size: 2.9rem;
	font-weight: 500
}

.home .fp-tableCell {
	display: block
}

.pricingConetnt {
	font-size: 1rem;
	font-weight: 700;
	border-radius: .25rem;
	color: #18e7d3
}

.menu-item {
	display: inline-block;
	margin-right: 20px;
	/* Adjust margin as needed */
}

.menu-item:hover .nav-link {
	background-color: #18e7d3;
	border-radius: 5px;
	font-weight: 500 !important;
	color: white !important;
}